<template>
    <div class="doctorAddEdit">
        <div class="contents pd-20-30">
            <el-row>
                <el-col :span="24">
                    <el-button type="text" class="labels middle">回复模板</el-button>
                    <el-button class="fr middle" type="primary" plain size="small" @click="addMUBAN(1)" >新增模板</el-button>
                </el-col>
                <el-col :span="24">
                    <div>
                        <el-table
                            :data="list"
                            :header-row-class-name="'table_header'"
                            :empty-text="tableTip"
                            style="width: 100%"
                            :height="250"
                            class="reply-table"
                             
                        >>
                            <el-table-column prop="title" label="模板标题" ></el-table-column>
                            <el-table-column prop="content" label="模板内容" ></el-table-column>
                            <el-table-column prop="addtime" label="添加时间" ></el-table-column>
                            <el-table-column prop="name" label="操作" fixed="right" width="200">
                                <template slot-scope="scope">
                                    <el-button type="text" @click.stop="skipInfo(scope.row.id,1)">编辑</el-button>
                                    <el-button type="text" @click.stop="deleteItem(scope.row.id,1)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-col>
            </el-row>
            <el-row class="mt-20">
                <el-col :span="24">
                    <el-button type="text" class="labels middle">小结模板</el-button>
                    <el-button class="fr middle" type="primary" plain size="small" @click="addMUBAN(2)" >新增小结</el-button>
                </el-col>
                <el-col :span="24">
                    <div>
                        <el-table
                            :data="list1"
                            :header-row-class-name="'table_header'"
                            :empty-text="tableTip"
                            style="width: 100%"
                            :height="250"
                            class="reply-table"
                        >>
                            <el-table-column prop="title" label="小结标题"></el-table-column>
                            <el-table-column prop="content" label="小结内容" ></el-table-column>
                            <el-table-column prop="addtime" label="添加时间" ></el-table-column>
                            <el-table-column prop="name" label="操作" fixed="right" width="200">
                                <template slot-scope="scope">
                                    <el-button type="text" @click.stop="skipInfo(scope.row.id,2)">编辑</el-button>
                                    <el-button type="text" @click.stop="deleteItem(scope.row.id,2)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            width="500px"
            top="15%"
            >
            <div>
                <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="demo-ruleForm" v-loading="loading">
                    <el-row>
                        <el-col>
                            <el-form-item :label="names" prop="name">
                                <el-input v-model="form.name" maxlength="10" placeholder="请输入限制10字以内"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                     <el-row>
                        <el-col>
                            <el-form-item :label="content" prop="contents">
                                <el-input type="textarea" v-model="form.contents"  maxlength="500" placeholder="请输入限制500字以内"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!-- 按钮 -->
            <div class="account-footer clearfix text-center">
                <el-button size="small" @click="dialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary"  :loading="loadings" @click="submitForm('form')">{{loadings?'保 存 中':'保  存'}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { DelLanguag } from "../../api/api";
import { EditLanguag } from "../../api/api";
import { LanguagInfo } from "../../api/api";
import { AddLanguage } from "../../api/api";
import { LanguageList } from "../../api/api";
import { get } from 'http';
export default {
    components:{
    },
    data() {
    
        return {
            did:this.$route.query.doctor_info_id,
            dialogVisible:false,
            dialogTitle:'',//弹框标题
            names:'',
            content:'',
            list:[],//muban
            list1:[],//xiaojie
            tableTip:'系统正在检索中…',
            form:{
                name:'',
                contents:'',
            },
            /**
             * 校验表单
             */
            rules:{
                name: [
                    {
                        required: true,
                        trigger: "blur",
                        message:'请输入，不能为空'
                    }
                ],
                contents: [
                    {
                        required: true,
                        trigger: "blur",
                        message:'请输入，不能为空'
                    }
                ],
            },
            loading:false,
            editId:'',
            loadings:false,
        };
    },
    created() {

    },
    mounted() {
        this.getLanguageList(1);
        this.getLanguageList(2);
    },
    methods: {
        addMUBAN(type){
            this.dialogVisible = !this.dialogVisible;
            
            if(this.dialogVisible){
                this.form.name = '';
                this.form.contents = '';
                this.editId = '';
            }

            if(type == 1){
                this.names = '模板标题';
                this.content = '模板内容';
                this.dialogTitle = '模板';
            }else{
                this.names = '小结标题';
                this.content = '小结内容';
                this.dialogTitle= '小结';
            }
        },
        //获取列表
        getLanguageList(type) {
            var that = this;
            var db = {
                doctor_info_id: this.did, //医生的id
                type:type,
            };
            this.$axios.post(LanguageList,db).then(res => {
                    if(res.data.code == 1){
                        if(type == 1){ 
                            this.list = res.data.data;
                        }
                        if(type == 2){
                            this.list1 = res.data.data;
                        }
                    }else{
                        if(type == 1){
                            this.list = [];
                            this.tableTip = '暂无模板';
                        }
                        if(type == 2){
                            this.list1 = [];
                            this.tableTip = '暂无模板';
                        }
                    }
                    this.dialogVisible = false;
                }, err => {

                });
        },
        //编辑小结
        skipInfo(id,type){
            //获取详情
            this.addMUBAN(type);
            var db = {
                DID: this.did, //医生的id
                LangId: id //	常用语id
            }
            this.loading = true;
            this.$axios.post(LanguagInfo,db).then(res => {
                    this.loading = false;
                    if(res.data.code == 1){
                        this.form.name = res.data.data.Title;
                        this.form.contents = res.data.data.Content;
                        this.editId = db.LangId;
                    }else{
                        this.form.name = '';
                        this.form.contents = '';
                    }
                }, err => {

                });
        },
        //提交
        submitForm(formName){
            this.$refs[formName].validate(valid => {
                if (valid) {
                    
                    var db = {
                        DID: this.did, //医生的id
                        Title: this.form.name, //	标题
                        Content: this.form.contents, //	内容
                        Type: this.dialogTitle == '模板' ? 1 : 2, //类型，1常用语，2小结
                    };   

                    if(this.editId == ''){
                        var url = AddLanguage;
                    }else{
                        db.LangId = this.editId;
                        var url = EditLanguag;
                    }
                    this.loadings = true;
                    this.$axios.post(url,db).then(res => {
                        this.loadings = false;
                            if(res.data.code == 1){
                                this.$message.success(res.data.msg);
                                this.getLanguageList(db.Type);
                            }else{
                                this.$message.error(res.data.msg);
                            }
                            this.dialogVisible = false;
                        }, err => {
                            this.loadings = false;
                        });
                    
                }
            });
        },
        //删除
        deleteItem(id,types){

            this.$confirm('确定删除吗？', "系统提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            })
                .then(() => {
                    var db = {
                        DID: this.did, //医生的id
                        LangId: id //	常用语id
                    }
                    this.$axios.post(DelLanguag,db).then(res => {
                    if(res.data.code == 1){
                        this.getLanguageList(types);
                    }else{
                        this.getLanguageList(types);
                    }
                }, err => {

                });
            });
            
        },

    }
}
</script>
<style lang="scss">
.contents{
    height: calc(100vh - 270px);
}
.labels{
    color: #666;
    font-size: 14px;
}
.reply-table{
    th div,.cell{
        padding-left: 0px;
    }
}

</style>


